import {ComputerDesktopIcon, DocumentTextIcon, UserPlusIcon} from '@heroicons/react/24/outline';
import {IMenuGroup, IMenuItem} from '@components/Sidebar';
import {PrivateRoutes} from '@constants/Route';
import {ProductTypes} from '@constants/Common';

enum MenuPrefixes {
  ABN = '/abn',
  BareTrust = '/bare-trust',
  BusinessName = '/business-name',
  Company = '/company',
  ConstitutionConversion = '/constitution-conversion',
  DiscretionaryTrust = '/discretionary-trust',
  Superfund = '/superfund',
  UnitTrust = '/unit-trust',
  OrderMonitor = '/order-monitor',
  AccountRegistration = '/account-registration',
}

export const ORDER_FLOW_MENUS: IMenuItem[] = [
  {
    url: PrivateRoutes.BareTrust,
    label: ProductTypes.BareTrust,
    prefix: MenuPrefixes.BareTrust,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.BusinessName,
    label: ProductTypes.BusinessName,
    prefix: MenuPrefixes.BusinessName,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.Company,
    label: ProductTypes.Company,
    prefix: MenuPrefixes.Company,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.ConstitutionConversion,
    label: ProductTypes.ConstitutionConversion,
    prefix: MenuPrefixes.ConstitutionConversion,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.DiscretionaryTrust,
    label: ProductTypes.DiscretionaryTrust,
    prefix: MenuPrefixes.DiscretionaryTrust,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.Superfund,
    label: ProductTypes.Superfund,
    prefix: MenuPrefixes.Superfund,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.UnitTrust,
    label: ProductTypes.UnitTrust,
    prefix: MenuPrefixes.UnitTrust,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
  {
    url: PrivateRoutes.ABN,
    label: ProductTypes.Abn,
    prefix: MenuPrefixes.ABN,
    icon: <DocumentTextIcon className="w-6 h-6" />,
  },
];

export const PUBLIC_MENU_GROUPS: IMenuGroup[] = [
  {
    title: 'Order a Document',
    menus: ORDER_FLOW_MENUS,
  },
];

export const PRIVATE_MENU_GROUPS: IMenuGroup[] = [
  {
    menus: [
      {
        url: PrivateRoutes.OrderMonitor,
        label: 'Order Monitor',
        prefix: MenuPrefixes.OrderMonitor,
        icon: <ComputerDesktopIcon className="w-6 h-6" />,
      },
    ],
  },
  {...PUBLIC_MENU_GROUPS[0]},
];

export const ACCOUNT_REGISTRATION_MENU: IMenuItem = {
  url: PrivateRoutes.AccountRegistration,
  label: 'Account Registration',
  prefix: MenuPrefixes.AccountRegistration,
  icon: <UserPlusIcon className="w-6 h-6" />,
};
