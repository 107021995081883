import {FC, useState, useEffect} from 'react';
import {ChevronDownIcon} from '@heroicons/react/24/solid';
import {IMenuItem, ISubMenuItem} from '@components/Sidebar';
import Tooltip from '@components/Tooltip';

interface ISideBarItemProps {
  isMainMenuOpen: boolean;
  id: string;
  currentUrl: string | null;
  menu: IMenuItem;
  isButton?: boolean;
  onClick?: () => void;
}

const SideBarItem: FC<ISideBarItemProps> = ({isMainMenuOpen, id, currentUrl, menu, isButton, onClick}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {url, label, icon, prefix, subMenus} = menu;
  let children: ISubMenuItem[] = subMenus || [];
  const isActiveMenu = currentUrl?.includes(prefix as string);
  const lowerLabel = label.replaceAll(' ', '-').toLowerCase();

  useEffect(() => {
    if (subMenus && subMenus.length > 0) {
      subMenus.forEach(subMenu => {
        if (subMenu.url === currentUrl) {
          setIsOpen(!isOpen);
        }
      });
    }
  }, [subMenus]);

  const goToPageOrToggleOpen = () => {
    if (isButton && onClick) {
      onClick();
    } else {
      if (!children.length && url) {
        window.location.href = url;
      } else {
        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <ul
      data-test-id={`sidebar-item-${id}-${lowerLabel}-ul`}
      className={`flex justify-between items-center my-[2px] ${isActiveMenu ? 'pl-2' : 'px-2'}`}>
      <li className="w-full">
        <details data-test-id={`sidebar-item-${id}-${lowerLabel}-details`}>
          <summary
            data-test-id={`sidebar-item-${id}-${lowerLabel}-summary`}
            className={`flex justify-between gap-2 cursor-pointer items-center text-[#F8FAFC] pt-2 pr-3 pb-2 pl-2 transition-[background-color] ease duration-350 ${
              isActiveMenu ? 'bg-[#133A64] rounded-md rounded-e-none' : 'rounded-md'
            } hover:bg-[#133A64] w-full`}
            onClick={goToPageOrToggleOpen}>
            <div className="flex items-center gap-3 w-full">
              {!isMainMenuOpen && !children.length ? (
                <div className="group w-max flex items-center">
                  <button
                    data-test-id={`sidebar-${id}-${lowerLabel}-icon`}
                    data-tooltip-id={`sidebar-${id}-${lowerLabel}-icon`}
                    className={`${isActiveMenu ? 'text-white' : 'text-[#9AA4B2]'} group-hover:text-white`}>
                    {icon}
                  </button>
                  <Tooltip theme="dark" reference={`sidebar-${id}-${lowerLabel}-icon`} position="right" title={label} />
                </div>
              ) : null}
              {!isMainMenuOpen && children.length ? (
                <>
                  <button
                    data-test-id={`sidebar-${id}-${lowerLabel}-icon`}
                    data-tooltip-id={`sidebar-${id}-${lowerLabel}-icon`}
                    className="text-[#9AA4B2] hover:text-white">
                    {icon}
                  </button>
                  <Tooltip
                    theme="dark"
                    reference={`sidebar-${id}-${lowerLabel}-icon`}
                    position="right"
                    title={label}
                    content={
                      <ul className="overflow-y-auto h-full max-h-24">
                        {children.map((subMenuItem: ISubMenuItem, subMenuIndex: number) => (
                          <li key={subMenuIndex}>
                            <a
                              data-test-id={`sidebar-${subMenuIndex}-child-a`}
                              className={`text-sm leading-5 font-normal block transition duration-300 ease-in-out cursor-pointer ${
                                subMenuItem.url === currentUrl ? 'bg-[#133A64]' : ''
                              } text-[#EEF2F6] rounded-md p-2 hover:bg-[#133A64]`}
                              href={subMenuItem.url}>
                              {subMenuItem.subMenuTitle}
                            </a>
                          </li>
                        ))}
                      </ul>
                    }
                  />
                </>
              ) : null}
              {isMainMenuOpen && (
                <div className="group flex items-center gap-3 w-full">
                  <button
                    data-test-id={`sidebar-${id}-${lowerLabel}-icon`}
                    className={`${isActiveMenu ? 'text-white' : 'text-[#9AA4B2]'} group-hover:text-white`}>
                    {icon}
                  </button>
                  <div data-test-id={`sidebar-${id}-${lowerLabel}-title`} className="text-[#EEF2F6] text-sm leading-5 font-medium">
                    {label}
                  </div>
                </div>
              )}
            </div>
            {children.length > 0 && isMainMenuOpen && (
              <span className={`shrink-0 transition duration-100 ${isOpen ? 'rotate-180 transform' : ''} text-sm text-[#9AA4B2]`}>
                <ChevronDownIcon className="w-[1rem] h-[1rem]" strokeWidth={2.5} />
              </span>
            )}
          </summary>
          {children.length > 0 && isMainMenuOpen && isOpen && (
            <ul>
              {children.map((subMenuItem: ISubMenuItem, subMenuIndex: number) => (
                <li key={subMenuIndex}>
                  <a
                    data-test-id={`sidebar-${subMenuIndex}-child-a`}
                    className={`text-sm leading-5 font-normal block transition duration-300 ease-in-out cursor-pointer ${
                      subMenuItem.url === currentUrl ? 'bg-[#133A64]' : ''
                    } text-[#EEF2F6] rounded-md pl-11 pt-2 pr-3 pb-2 hover:bg-[#133A64]`}
                    href={subMenuItem.url}>
                    {subMenuItem.subMenuTitle}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </details>
      </li>
      {isActiveMenu && (
        <div
          style={{
            width: 0,
            height: 0,
            position: 'absolute',
            right: 0,
            left: isMainMenuOpen ? 264 : 60,
            borderTop: '10px solid transparent',
            borderLeft: '10px solid #133A64',
            borderBottom: '10px solid transparent',
          }}
        />
      )}
    </ul>
  );
};

export default SideBarItem;
