import {FC, PropsWithChildren, useEffect} from 'react';
import {Session} from '@auth/core/types';
import {useStore} from '@nanostores/react';
import * as Sentry from '@sentry/browser';
import {isMenuOpen} from '@stores/AppUIStore';
import {$authSession, IAuthSession} from '@stores/AuthStore';
import {$appSecrets, IAppSecrets} from '@stores/AppUIStore';
import {userLogout} from '@helpers/auth';
import SideBar from '@components/Sidebar';

interface IPrivateLayoutMainProps {
  currentUrl: string;
  session: Session | null;
  appSecrets: IAppSecrets;
}

const PrivateLayoutMain: FC<PropsWithChildren<IPrivateLayoutMainProps>> = ({children, currentUrl, session, appSecrets}) => {
  const $isMenuOpen = useStore(isMenuOpen);
  const currentSession = useStore($authSession);

  const validateTokenExpiry = async () => {
    Sentry.setUser(null);

    // Signs out user if access token is expired
    if (currentSession && currentSession.exp * 1000 < Date.now()) {
      userLogout();
    } else {
      const user: Sentry.User = {
        id: currentSession?.user?.id ?? undefined,
        email: currentSession?.user?.email ?? undefined,
      };

      Sentry.setUser(user);
    }
  };

  useEffect(() => {
    $appSecrets.set(appSecrets);
  }, [appSecrets]);

  useEffect(() => {
    $authSession.set(session as IAuthSession);
  }, [session]);

  validateTokenExpiry();

  return (
    <>
      <SideBar currentUrl={currentUrl} />
      <main className={`ml-[3.8rem] overflow-auto min-h-screen bg-[#F8FAFC] w-full ${$isMenuOpen ? 'hidden md:ml-[16.5rem] md:block' : ''}`}>
        {children}
      </main>
    </>
  );
};

export default PrivateLayoutMain;
